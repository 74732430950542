import React, {useState, useRef} from 'react';
import { css } from '@emotion/react';
import { StaticQuery, graphql } from "gatsby"
import { wrapper, TitleHeader} from '../components/defaults/styles/elements';
import Questionmark from '../components/defaults/assets/svg/questionmark.svg';
import { color } from '../components/defaults/styles/theme';
import { ChevronRight } from '../components/defaults/assets/svg/pagination';

const FAQitem = ({data}) => {
  const [itemActive, setItemActive] = useState("");
  const [itemHeight, setItemHeight] = useState('0px');
  const content = useRef(null);

  function toggleFAQitem() {
    setItemActive(itemActive === "" ? "active" : "");
    setItemHeight(
        itemActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }



  return (
    <div css={css`
      :not(:last-of-type) {
        margin-bottom: .75em;
      }
    `}>
      <div onClick={toggleFAQitem} role = "button" onKeyDown={toggleFAQitem} tabIndex={0} css={css`
        background: ${color.accent_light};
        border-radius: 5px;
        font-size: .85em;
        padding: 1em;
        display: flex;
        font-weight: 600;
        cursor: pointer;
      `}>
        <div css={css`
          display: flex;
          align-items: center;
          margin-right: .5em;
          svg {
            height: 1em;
            width: 1em;
            transform: ${itemActive ? 'rotate(90deg)' : 'rotate(0)' };
            transition: transform .4s ease-in-out;
          }
        `}>
          <ChevronRight />
        </div>
        <div>
          <p css={css`
            margin-bottom: 0;
          `}>{data.question}</p>
        </div>
      </div>
      <div ref={content} css={css`
        max-height: ${itemHeight};
        overflow: hidden;
        transition: max-height .4s ease-in-out;
      `}>
        <FAQCollapsable answer={data.answer.childMarkdownRemark.html} />
      </div>
    </div>
  )
}

const FAQCollapsable = ({answer, className}) => {
  return (
    <div className={className} dangerouslySetInnerHTML={{__html: answer}} css={css`
      padding: .5em 2em 1.5em;
      p, a {
        font-size: .85rem;
        margin-bottom: .5em;
      }
      a {
        color: ${color.cta_green};
        font-weight: 600;
        text-decoration: none;
      }
    `} />
  )
}

const ItemQuery = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allAirtable(filter: {table: {eq: "faq"}}, sort: {fields: data___sort, order: ASC}) {
            edges {
              node {
                data {
                  answer {
                    childMarkdownRemark {
                      html
                    }
                  }
                  question
                }
              }
            }
          }
        }
      `}
      render={data => data.allAirtable.edges.map((item, index) => (
          <FAQitem data={item.node.data} key={index} />
      ))}
    ></StaticQuery>
  )
}



const FAQ = ({...props}) => {
  return (
    <>
      <section id="faq" css={css`
        background: ${props.theme ? props.theme : "white"};
        position: relative;
        z-index: 1;
        &:after {
          content: '';
          background: ${color.main_dark};
          height: 1em;
          bottom: 0;
          left: 0;
          width: 100%;
          position: absolute;
          z-index: -1;
        }
      `}>
        <div css={[wrapper, css`
          max-width: 55rem;
          position: relative;
          z-index: 1;
          &:before {
            content: '';
            background: white;
            height: 1em;
            bottom: 0;
            left: 0;
            width: calc(100% - 2em);
            position: absolute;
            z-index: -1;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            max-width: 53em;
            margin-left: 1em;
          }
        `]}>
          <TitleHeader inline title="The official EXPO 9 FAQ" subtitle="also known as – all the things you might ask yourself." transparent icon={<Questionmark />} />
          <ItemQuery />
        </div>
      </section>
      <section css={css`
        background: ${color.main_dark};
      `}>
        <div css={[wrapper, css`
          max-width: 55rem;
          padding-top: 2em;
          p {
            margin-bottom: 0;
            color: white;
            font-size: .85em;
          }
        `]}>
          <p>
            Still questions we did not cover in our FAQ?
          </p>
          <p>That certainly means it is time to send us a message…</p>
        </div>
      </section>
    </>
  )
}

export default FAQ;